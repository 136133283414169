import React, { useState } from 'react';
import { Box, Grid, Paper, IconButton, Tooltip } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LeftPanel from '../components/LeftPanel';
import MapComponent from '../components/MapComponent';
import ErrorBoundary from '../components/ErrorBoundary';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const ProjectsListPage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const theme = useTheme(); 
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
      <Grid container sx={{ flex: 1 }}>
        {/* Left Panel */}
        {!collapsed && (
          <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Paper sx={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
              <ErrorBoundary>
                <LeftPanel collapsed={collapsed} setCollapsed={setCollapsed} />
              </ErrorBoundary>
            </Paper>
          </Grid>
        )}
        {/* Map Component */}
        <Grid
          item
          xs={12}
          md={collapsed ? 12 : 9}
          sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Paper sx={{ p: 0, flex: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                height: '100%',
              }}
            >
              <ErrorBoundary>
                <MapComponent
                  center={[139.6917, 35.6895]}
                  zoom={6}
                  highResolution={true}
                  drawTools={false}
                  overlay={false}
                  main={true}
                  collapsed={collapsed}
                />
              </ErrorBoundary>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      
      {/* Floating Toggle Button with Vertical White Space */}
      {collapsed && (
        <Box
          sx={{
            position: 'fixed',
            left: 0,
            top: '15%',
            transform: 'translateY(-50%)',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'background.paper',
            boxShadow: 3,
            borderRadius: '0 8px 8px 0',
            zIndex: 1300,
            paddingY: 2, // Vertical padding for extra white space
            width: '40px', // Width of the toggle bar
          }}
        >
          <Tooltip title={t('app.expandPanel')} arrow>
            <IconButton
              onClick={() => setCollapsed(false)}
              sx={{
                color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit', // White in dark mode
              }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default ProjectsListPage;
