import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Box, Typography, IconButton, Paper } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Custom styles for the modal
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    minWidth: '300px', // Reduced width from 400px to 300px for a compact layout
    padding: theme.spacing(2),
  },
}));

// Custom styles for the "Processing..." heading
const StyledHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  textAlign: 'left',
  marginBottom: theme.spacing(2),
}));

// Custom styles for the message text
const StyledMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
}));

// Custom styles for the buttons
const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  textTransform: 'none',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: 'inherit', // Prevent background color change on hover
  },
}));

// Custom paper for the success message
const SuccessMessagePaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[1],
}));


const ContentBox = styled(Box)(({ theme, status }) => ({
    border: status !== 'success' ? '2px solid black' : 'none',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  }));
  

const ProcessingModal = ({ open, status, onClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { t } = useTranslation();

  return (
    <StyledDialog
      open={open}
      onClose={() => { if (status === 'success') onClose(); }}
      aria-labelledby="processing-dialog-title"
    >
      <ContentBox status={status}>
        {/* Processing or Success Content */}
        {status === 'success' ? (
          <>
            {/* Success Message */}
            <DialogContent>
              <SuccessMessagePaper elevation={1}>
                <CheckCircleIcon color="success" style={{ marginRight: theme.spacing(1), fontSize: 30 }} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  {t('app.successMessage')}
                </Typography>
                <IconButton
                  size="small"
                  onClick={onClose}
                  style={{ marginLeft: 'auto' }}
                >
                  <CloseIcon />
                </IconButton>
              </SuccessMessagePaper>
            </DialogContent>
          </>
        ) : (
          <>
            {/* Processing Heading */}
            <StyledHeading variant="h6">{t('app.processingHeading')}</StyledHeading>

            {/* Processing Message */}
            <DialogContent>
              <StyledMessage variant="body1">
                {t('app.processingMessage')}
              </StyledMessage>
            </DialogContent>
          </>
        )}

        {/* Dialog Actions with Buttons */}
        <DialogActions style={{ justifyContent: 'flex-end', gap: theme.spacing(1) }}>
          {/* Return Home Button */}
          <StyledButton
            onClick={() => navigate('/')}
            variant="text"
            startIcon={<ArrowBackIcon />}
          >
            {t('app.returnHome')}
          </StyledButton>

          {/* Report Button */}
          <StyledButton
            onClick={() => navigate(`/projectreport/${projectId}`)}
            variant={status === 'success' ? "contained" : "text"}
            startIcon={<AssessmentIcon />}
            disabled={status !== 'success'}
            sx={{ bgcolor: status === 'success' ? theme.palette.primary.main : undefined, color: status === 'success' ? theme.palette.common.white : undefined }}
          >
            {t('app.report')}
          </StyledButton>
        </DialogActions>
      </ContentBox>
    </StyledDialog>
  );
};

export default ProcessingModal;
