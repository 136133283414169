import React, { useState } from 'react';
import { 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Button, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {polygonOptions} from '../resources/projectFormOptions';
import { useTranslation } from 'react-i18next';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 1.5,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  '& .MuiOutlinedInput-root': {
    padding: theme.spacing(0.75),
    fontSize: '0.875rem',
    '& fieldset': {
      borderColor: theme.palette.grey[300],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  padding: theme.spacing(0.5, 1),
  fontSize: '0.875rem',
  boxShadow: 'none',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    boxShadow: theme.shadows[2],
  },
}));

const LandcoverPopup = ({ open, onClose, onSave }) => {
  const [landcoverType, setLandcoverType] = useState('');
  const { t, i18n } = useTranslation();

  const handleSave = () => {
    onSave(landcoverType);
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ pb: 1, fontWeight: 'bold', fontSize: '1.25rem' }}>
        {t('app.selectLandCoverType')}
      </DialogTitle>
      <DialogContent dividers sx={{ pt: 1 }}>
        <Box component="form" noValidate autoComplete="off" sx={{ mt: 1 }}>
          <StyledFormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel id="landcover-type-label">{t('app.landCoverType')}</InputLabel>
            <Select
              labelId="landcover-type-label"
              value={landcoverType}
              onChange={(e) => setLandcoverType(e.target.value)}
              label={t('app.landCoverType')}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              {polygonOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                     {i18n.language === 'ja' ? option.title : option.label}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pt: 2 }}>
        <StyledButton
          variant="outlined"
          onClick={onClose}
          color="primary"
          sx={{
            mr: 1,
            '&:hover': {
              backgroundColor: 'rgba(53, 108, 182, 0.08)',
            },
          }}
        >
          {t('app.cancel')}
        </StyledButton>
        <StyledButton
          variant="contained"
          onClick={handleSave}
          color="primary"
          disabled={!landcoverType}
          sx={{
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
        >
          {t('app.save')}
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default LandcoverPopup;
