import { createSlice } from '@reduxjs/toolkit';

const errorSlice = createSlice({
    name: 'error',
    initialState: {
        serverError: false, 
    },
    reducers: {
        setServerError: (state, action) => {
            state.serverError = true;
        },
        clearServerError: (state) => {
            state.serverError = false;
        },
    },
});

export const { setServerError, clearServerError } = errorSlice.actions;

export default errorSlice.reducer;
