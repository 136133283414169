import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
import { logout } from '../utils/authUtils/logout';

const Error500Page = ({ hasLogout }) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100vw',
                textAlign: 'center',
                padding: 3,
                minHeight: '100vh'
            }}
        >
            {/* Graphical Element */}
            <ErrorOutlineIcon
                sx={{ fontSize: 90, color: 'primary.main', marginBottom: 2 }}
            />

            {/* Title */}
            <Typography variant="h4" color="textPrimary" fontWeight={"bold"} gutterBottom>
                {`500 - ${t('app.serverError')}`}
            </Typography>

            {/* Message */}
            <Typography variant="body1" color="textSecondary" gutterBottom>
                {t('app.serverErrorMessage')}
            </Typography>

            {/* Action Button */}
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    if (hasLogout) {
                        logout();
                    } else {
                        window.location.href = '/';
                    }
                }}
                sx={{
                    marginTop: 3,
                    ":hover": {
                        backgroundColor: "primary.main",
                    }
                }}

            >
                {hasLogout ? "Logout" : t('app.backToHome')}
            </Button>
        </Box>
    );
};

export default Error500Page;
