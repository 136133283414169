import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, Alert } from '@mui/material';
import { clearServerError } from '../redux/errorSlice';
import { useTranslation } from 'react-i18next';

const ServerErrorToast = () => {
    const dispatch = useDispatch();
    const serverError = useSelector((state) => state.error.serverError);   
    const { t } = useTranslation() 

    const handleClose = () => {
        dispatch(clearServerError()); 
    };

    return (
        <Snackbar
            open={serverError}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={handleClose} severity="error" variant="filled">
                {t("app.serverErrorToastMessage")}
            </Alert>
        </Snackbar>
    );
};

export default ServerErrorToast;
