// socketSlice.js
import { createSlice } from '@reduxjs/toolkit';
import io from 'socket.io-client';

// Initial state for the socket management
const initialState = {
  isConnected: false,
};

// Create a slice for socket management
const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    // Action to set the connection status
    setConnectionStatus: (state, action) => {
      state.isConnected = action.payload;
    },
  },
});

// Export the action creators
export const { setConnectionStatus } = socketSlice.actions;

// Export the reducer
export default socketSlice.reducer;

// Initialize the socket connection
export const socket = io(`${process.env.REACT_APP_UE_SOCKET_PORT}`, {
  autoConnect: false, // Manage connection manually
});

// Thunk function to initialize the socket
export const initializeSocket = () => (dispatch) => {
  // Connect the socket
  socket.connect();

  // Listen to 'connect' event
  socket.on('connect', () => {
    dispatch(setConnectionStatus(true));
  });

  // Listen to 'disconnect' event
  socket.on('disconnect', () => {
    dispatch(setConnectionStatus(false));
  });

  // Optionally, handle connection errors
  socket.on('connect_error', (error) => {
    console.error('Socket connection error:', error);
    // You might want to dispatch some error handling actions here
  });

  // Return the socket instance for further use
  return socket;
};
