/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  TextField,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Alert,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { useDispatch } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AssessmentIcon from "@mui/icons-material/Assessment";

import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import DateSelector from "./DateSelector";
import { socket } from "../redux/socketSlice"; // Import socket directly
import ProcessingModal from "./ProcessingModal";
import {
  setCurrentProjectData,
  setIsModeling,
  setCurrentModelingProjectId,
  setShapeStatus,
  setProjectStatus,
} from "../redux/projectSlice";
import {
  createProject,
  updateProject,
  getProjectById,
  initiateModeling,
  checkResultsById,
  getShapesByProjectId,
} from "../api/project";
import {
  designedByOptions,
  statusOptions,
} from "../resources/projectFormOptions";

import { useSelector } from "react-redux";
import { ERROR_CODES } from "../resources/errorCode";

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius * 1.5,
  height: "100%",
  position: "relative",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "6px",
    backgroundColor: "transparent",
  },
  "&:hover::-webkit-scrollbar": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "transparent",
    borderRadius: "4px",
  },
  "&:hover::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const MaskOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 10,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  "& .MuiOutlinedInput-root": {
    padding: theme.spacing(0.75),
    fontSize: "0.875rem",
    "& fieldset": {
      borderColor: theme.palette.grey[300],
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  marginBottom: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  minWidth: "110px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0.5, 1),
  fontSize: "0.875rem",
  borderRadius: theme.shape.borderRadius,
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const BackButton = styled(Button)(({ theme }) => ({
  alignSelf: "flex-start",
  marginBottom: theme.spacing(1.5),
  color:
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : // White text in dark theme
        theme.palette.primary.main,
  fontSize: "0.875rem",
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.primary,
  fontSize: "0.875rem",
}));

const SectionBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1.5),
  boxShadow: theme.shadows[1],
}));

const ProjectForm = ({ collapsed, setCollapsed }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const { projectId } = useParams();
  const [hasShapes, setHasShapes] = useState(false);
  const navigate = useNavigate();
  const [hasCompleteScopeOfWork, setHasCompleteScopeOfWork] = useState(false);
  const userId = useSelector((state) => state.auth.userId);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const [formValues, setFormValues] = useState({
    name: "",
    scheduledDate: "",
    scheduledEndDate: "",
    plannedAmount: "",
    designedBy: "",
    // Keep this as the default
    publicPrivate: "public",
    // Set this to empty string or a default value like 'inOperation'
    status: "",
    // Initialize as default model
    models: [1],
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  // const [socketData, setSocketData] = useState(null);
  const [isReceiving, setIsReceiving] = useState(false);
  const [saveAndRunButtonDisabled, setSaveAndRunButtonDisabled] =
    useState(false);
  const [resultButton, setResultButton] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [unauthorizedAccess, setUnauthorizedAccess] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [hasProjectWithSameName, setHasProjectWithSameName] = useState(false);

  const [processStatus, setProcessStatus] = useState("initiating");
  const shapeStatus = useSelector((state) => state.project.shapeStatus);
  // error state
  const [error, setError] = useState(null);

  const loadProjectData = useCallback(
    async (id) => {
      setIsLoading(true);
      try {
        const data = await getProjectById(id);
        dispatch(setCurrentProjectData(data));

        setFormValues({
          name: data.name || "",
          scheduledDate: data.start_date || "",
          scheduledEndDate: data.end_date || "",
          plannedAmount:
            data.planned_amount !== null ? data.planned_amount : "",
          designedBy:
            designedByOptions.find((opt) => opt.value === data.designed_by)
              ?.value || "",
          publicPrivate: data.public ? "public" : "private",
          status: data.status !== "NULL" ? data.status : "",
          models: data.models ? JSON.parse(data.models) : [],
        });
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setUnauthorizedAccess(true);
          navigate("/");
        } else {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, isAdmin, navigate]
  );

  const loadResults = useCallback(async (id) => {
    try {
      const results = await checkResultsById(id);
      // Enable if results are not empty
      setResultButton(results);
    } catch (error) {
      console.error("Failed to fetch results:", error);
      setError(error.message);
    }
  }, []);

  const loadShapes = useCallback(async (id) => {
    try {
      // Fetch shapes and check for "completeScopeOfWork"
      const shapes = await getShapesByProjectId(id);
      setHasShapes(shapes.length > 0);
      const completeScopeExists = shapes.some(
        (shape) => shape.landcover_type === "completeScopeOfWork"
      );
      setHasCompleteScopeOfWork(completeScopeExists);
    } catch (error) {
      console.log("error in fetching shapes", error);
      setError(error.message);
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Allow only valid numbers or empty for plannedAmount
    if (name === "plannedAmount") {
      const isNumericOrEmpty = value === "" || /^[0-9]+$/.test(value);
      setIsInvalid(!isNumericOrEmpty);
    }

    // Update form values if valid input
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleKeyDown = (e) => {
    // Allow only digits and essential control keys
    if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Tab") {
      e.preventDefault();
    }
  };

  const handleSubmit = async () => {
    if (isInvalid) return;
    setIsLoading(true);
    try {
      const payload = {
        name: formValues.name.trim(), // Trim spaces from name
        startdate: formValues.scheduledDate
          ? formValues.scheduledDate + "T14:30:00Z"
          : null,
        enddate: formValues.scheduledEndDate
          ? formValues.scheduledEndDate + "T14:30:00Z"
          : null,
        plannedamount: parseInt(formValues.plannedAmount, 10),
        designedby: formValues.designedBy,
        status: formValues.status,
        public: formValues.publicPrivate === "public",
        models: JSON.stringify(formValues.models),
        userId,
      };

      if (projectId) {
        await updateProject(projectId, payload);
      } else {
        const projectResponse = await createProject(payload);
        if (projectResponse) {
          navigate(`/project/${projectResponse}`);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setUnauthorizedAccess(true);
        navigate("/");
      }
      if (error.response &&
        error.response.status === 409 &&
        Object.keys(ERROR_CODES).includes(error.response.data.errorCode)
      ) {
        setHasProjectWithSameName(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRun = async () => {
    if (!hasCompleteScopeOfWork) {
      setSnackbarOpen(true);
      return;
    }

    try {
      if (projectId) {
        setSaveAndRunButtonDisabled(true);
        // setSocketData({ status: "Initiating" });
        dispatch(setIsModeling(true));
        dispatch(setCurrentModelingProjectId(projectId));

        const payload = {
          name: formValues.name,
          startdate: formValues.scheduledDate
            ? formValues.scheduledDate + "T14:30:00Z"
            : null,
          enddate: formValues.scheduledEndDate
            ? formValues.scheduledEndDate + "T14:30:00Z"
            : null,
          plannedamount: parseInt(formValues.plannedAmount, 10),
          designedby: formValues.designedBy,
          status: formValues.status,
          public: formValues.publicPrivate === "public",
          models: JSON.stringify(formValues.models),
        };

        await updateProject(projectId, payload);
        const modelingResponse = await initiateModeling(
          projectId,
          formValues.models
        );
        if (modelingResponse.status === 200) {
          setModalOpen(true);
        }

        setSnackbarOpen(true);
        dispatch(setIsModeling(false));
        dispatch(setCurrentModelingProjectId(null));
      }
    } catch (error) {
      console.error("Error running models:", error);
      setError(error.message);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseUnauthorizedSnackbar = () => {
    setUnauthorizedAccess(false);
  };

  useEffect(() => {
    if (projectId) {
      loadProjectData(projectId);
    }
  }, [loadProjectData, projectId]);

  useEffect(() => {
    if (projectId) {
      loadShapes(projectId);
      dispatch(setShapeStatus(false));
    }
  }, [projectId, shapeStatus]);

  useEffect(() => {
    if (projectId) {
      loadResults(projectId);
    }
  }, [loadResults, projectId, saveAndRunButtonDisabled]);

  useEffect(() => {
    socket.on("jobStatus", (data) => {
      if (data) {
        setIsReceiving(true);
        // Open modal when socket sends data
        setProcessStatus(data.status); // Update status based on data
        setSaveAndRunButtonDisabled(true);
        setResultButton(true);
        // setModalOpen(true)
      }

      if (data.status === "failure") {
        setIsReceiving(false);
      }

      if (data.status === "success") {
        setSaveAndRunButtonDisabled(false);
        setIsReceiving(false);
        setResultButton(false);
        dispatch(setProjectStatus("success"));
        // setModalOpen(false);
      }
    });
    return () => {
      socket.off("jobStatus");
    };
  }, [modalOpen]);

  // throwing error so that error boundary handle it
  if (error && error.includes("Network Error")) {
    throw error;
  }

  return (
    <FormContainer>
      <Tooltip title={t("app.collapsePanel")} arrow>
        <IconButton
          onClick={() => setCollapsed(true)}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "inherit",
          }}
        >
          <ChevronLeft />
        </IconButton>
      </Tooltip>
      {isLoading && (
        <MaskOverlay>
          <CircularProgress />
        </MaskOverlay>
      )}

      <BackButton startIcon={<ArrowBackIcon />} onClick={() => navigate("/")}>
        {t("app.back")}
      </BackButton>

      <StyledTextField
        label={t("app.name")}
        id="name"
        variant="outlined"
        fullWidth
        name="name"
        value={formValues.name}
        onChange={handleInputChange}
        required
        autoComplete="off"
      />
      <SectionBox>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <DateSelector
              label={t("app.scheduledConstructionPeriod")}
              id={t("app.scheduledConstructionPeriod")}
              value={formValues.scheduledDate}
              name="scheduledDate"
              handleChange={handleInputChange}
              maxDate={formValues.scheduledEndDate}
            />
            <Typography variant="body2" color="textSecondary">
              {t("app.startdate")}:{" "}
              {formValues.scheduledDate || `${t("app.unknown")}`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <DateSelector
              label={t("app.enddate")}
              id={t("app.enddate")}
              value={formValues.scheduledEndDate}
              name="scheduledEndDate"
              handleChange={handleInputChange}
              minDate={formValues.scheduledDate}
              disabled={formValues.scheduledDate === ""}
            />
            <Typography variant="body2" color="textSecondary">
              {t("app.enddate")}:{" "}
              {formValues.scheduledEndDate || `${t("app.unknown")}`}
            </Typography>
          </Grid>
        </Grid>
      </SectionBox>
      <SectionBox>
        <Grid container spacing={2}>
          {/* Planned Amount takes full width with suffix */}
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" gap={1}>
              <StyledTextField
                label={t("app.plannedAmount")}
                id={t("app.plannedAmount")}
                type="number"
                variant="outlined"
                fullWidth
                name="plannedAmount"
                value={formValues.plannedAmount}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                error={isInvalid}
                helperText={isInvalid ? `${t("app.decimalMessage")}` : ""}
              />
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", whiteSpace: "nowrap" }}
              >
                {t("app.millionYen")}
              </Typography>
            </Box>
          </Grid>

          {/* Designed By and Public/Private fields side by side */}
          <Grid item xs={12} md={6}>
            <StyledTextField
              label={t("app.designedBy")}
              select
              variant="outlined"
              fullWidth
              id="designedBy"
              name="designedBy"
              value={formValues.designedBy}
              onChange={handleInputChange}
              InputLabelProps={{
                component: "span",
              }}
            >
              {designedByOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {currentLanguage === "ja" ? option.title : option.label}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset" fullWidth>
              <StyledFormLabel component="legend" htmlFor="publicPrivate">
                {t("app.public")}/ {t("app.private")}
              </StyledFormLabel>
              <RadioGroup
                row
                name="publicPrivate"
                id="publicPrivate"
                value={formValues.publicPrivate}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="public"
                  control={<Radio />}
                  label={t("app.public")}
                />
                <FormControlLabel
                  value="private"
                  control={<Radio />}
                  label={t("app.private")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </SectionBox>

      <StyledTextField
        label={t("app.status")}
        id="status"
        select
        variant="outlined"
        fullWidth
        name="status"
        value={formValues.status || ""}
        onChange={handleInputChange}
        InputLabelProps={{
          component: "span",
        }}
      >
        {statusOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {currentLanguage === "ja" ? option.title : option.label}
          </MenuItem>
        ))}
      </StyledTextField>

      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            !formValues.name.trim() ||
            saveAndRunButtonDisabled ||
            isInvalid ||
            isReceiving
          }
        >
          <SaveIcon sx={{ marginRight: 1 }} />
          {t("app.save")}
        </StyledButton>

        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleRun}
          disabled={
            !formValues.name.trim() || saveAndRunButtonDisabled || !hasShapes
          }
        >
          <PlayArrowIcon sx={{ marginRight: 1 }} />
          {t("app.saveAndRun")}
        </StyledButton>

        <StyledButton
          onClick={() => navigate(`/projectreport/${projectId}`)}
          variant="contained"
          color="primary"
          disabled={!projectId || !resultButton || saveAndRunButtonDisabled}
        >
          <AssessmentIcon sx={{ marginRight: 1 }} />
          {t("app.report")}
        </StyledButton>
      </Box>

      <ProcessingModal
        open={modalOpen}
        status={processStatus}
        onClose={() => setModalOpen(false)}
      />

      {/* Snackbar for toast notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={hasCompleteScopeOfWork ? "success" : "warning"}
          sx={{
            width: "100%",
            backgroundColor: hasCompleteScopeOfWork
              ? "primary.main"
              : "warning.main",
            color: hasCompleteScopeOfWork
              ? "primary.contrastText"
              : "warning.contrastText",
            "& .MuiAlert-icon": {
              color: hasCompleteScopeOfWork
                ? "primary.contrastText"
                : "warning.contrastText",
            },
          }}
        >
          {hasCompleteScopeOfWork
            ? t("app.saveAndRunSuccess")
            : t("app.warningMessage")}
        </Alert>
      </Snackbar>

      <Snackbar
        open={unauthorizedAccess}
        autoHideDuration={3000}
        onClose={handleCloseUnauthorizedSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="error"
          onClose={handleCloseUnauthorizedSnackbar}
          sx={{ width: "100%" }}
        >
          {t("app.unauthorizedAccess")}
        </Alert>
      </Snackbar>

      <Snackbar
        open={hasProjectWithSameName}
        autoHideDuration={5000}
        onClose={() => setHasProjectWithSameName(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="error"
          onClose={() => setHasProjectWithSameName(false)}
          sx={{ width: "100%" }}
        >
          {t("app.projectNameExists")}
        </Alert>
      </Snackbar>
    </FormContainer>
  );
};

export default ProjectForm;
