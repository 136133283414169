import axiosAPI from "./axiosInstance";

const deleteUser = async (username) => {
  try {
      const response = await axiosAPI.delete(`/user/${username}`);
      return response.data;
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    }
};

const fetchUsers = async () => {
    try {
      const response = await axiosAPI.get('/users');
      return response.data;
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    }
};

const getUser = async (username) => {
  try {
      const response = await axiosAPI.get(`/user`);
      return response;
    } catch (error) {
      console.error("Error getting user:", error);
      throw error;
    }
};

const updateUserAttributes = async (username, attributes) => {
  try {
      const response = await axiosAPI.put(`/user/${username}`, attributes);
      return response.data;
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    }
};

export {
  deleteUser,
  fetchUsers,
  getUser,
  updateUserAttributes,
}
