import mapboxgl from 'mapbox-gl';

export const updateOutlineLayer = (mapInstance, bufferingData, lineColors) => {
    if (mapInstance.current && bufferingData) {
        const features = bufferingData.map((data) => ({
            type: "Feature",
            properties: { ...data },
            geometry: data.geom,
        }));

        if (mapInstance.current.getLayer("results-outline")) {
            mapInstance.current.removeLayer("results-outline");
        }
        if (mapInstance.current.getSource("results-outline")) {
            mapInstance.current.removeSource("results-outline");
        }

        mapInstance.current.addSource("results-outline", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features,
            },
        });

        mapInstance.current.addLayer({
            id: "results-outline",
            type: "line",
            source: "results-outline",
            layout: {
                "line-join": "round",
                "line-cap": "round",
            },
            paint: {
                "line-color": lineColors.resultRing,
                "line-width": 5,
            },
        });

        const currentCenter = mapInstance.current.getCenter();
        const currentZoom = mapInstance.current.getZoom();

        if (features.length > 0) {
            const bounds = new mapboxgl.LngLatBounds();
            features.forEach((feature) => {
                feature.geometry.coordinates[0].forEach((coord) => {
                    bounds.extend(coord);
                });
            });
            mapInstance.current.fitBounds(bounds, { padding: 20 });
        }

        // Restore the previous zoom and center
        mapInstance.current.flyTo({
            center: currentCenter,
            zoom: currentZoom,
        });

        mapInstance.current.on("mousemove", "results-outline", () => {
            mapInstance.current.getCanvas().style.cursor = "pointer";
        });

        mapInstance.current.on("mouseleave", "results-outline", () => {
            mapInstance.current.getCanvas().style.cursor = "";
        });
    }
};

