import mapboxgl from 'mapbox-gl';

export const panToProjectResults = (
    mapInstance,
    currentProjectId,
    center,
    zoom,
    showSnackbar,
    t
) => {
    if (mapInstance.current) {
        const source = mapInstance.current.getSource("result-ring");
        if (source && source._data && source._data.features) {
            // Find the feature that matches the current project ID
            const feature = source._data.features.find(
                (f) => f.properties.project_id === currentProjectId
            );

            if (feature && feature.geometry) {
                const { coordinates } = feature.geometry;

                // Ensure we handle both Polygon and MultiPolygon types
                const polygons =
                    feature.geometry.type === "Polygon" ? [coordinates] : coordinates;

                // Create bounds and extend for all polygon coordinates
                const bounds = new mapboxgl.LngLatBounds();
                polygons.forEach((polygon) => {
                    polygon.forEach((ring) => {
                        ring.forEach(([lng, lat]) => bounds.extend([lng, lat]));
                    });
                });

                if (!bounds.isEmpty()) {
                    mapInstance.current.fitBounds(bounds, {
                        padding: 20,
                        maxZoom: 10,
                        duration: 2000,
                    });
                } else {
                    // Pan to default if bounds are empty
                    mapInstance.current.flyTo({
                        center: center,
                        zoom: zoom,
                    });
                }
            } else {
                // No valid feature found, pan to default
                mapInstance.current.flyTo({
                    center: center,
                    zoom: zoom,
                });
                if (!feature && showSnackbar) showSnackbar(t("app.noModelingMessage"));
            }
        } else {
            // No source or features found, pan to default
            mapInstance.current.flyTo({
                center: center,
                zoom: zoom,
            });
        }
    }
};
