const basemapStyles = [
  {
      label: 'Street',
      value: 'mapbox://styles/mapbox/light-v11',
      translationKey: 'app.street', // Key for translation
  },
  {
      label: 'Dark Mode',
      value: 'mapbox://styles/mapbox/dark-v10',
      translationKey: 'app.darkMode', // Key for translation
  },
  {
      label: 'Basic Map',
      value: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',
      translationKey: 'app.basicMap', // Key for translation
  },
  {
      label: 'Aerial Photograph',
      value: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
      translationKey: 'app.aerialPhotograph', // Key for translation
  },
  {
      label: 'Hillshade Map',
      value: 'https://cyberjapandata.gsi.go.jp/xyz/hillshademap/{z}/{x}/{y}.png',
      translationKey: 'app.hillshadeMap', // Key for translation
  },
  // Add more basemap styles here as needed
];

export default basemapStyles;
