// mapInteractionUtils.js
import mapboxgl from "mapbox-gl";

export const setupMapInteractions = (map, dispatch, setCurrentProjectId) => {
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  // Handle mousemove for result-ring-fill layer
  map.on("mousemove", "result-ring-fill", (e) => {
    if (e.features.length > 0) {
      const feature = e.features[0];
      map.getCanvas().style.cursor = "pointer";
      popup
        .setLngLat(e.lngLat)
        .setHTML(`<h4 style="color : black;">${feature.properties.name}</h4>`)
        .addTo(map);
    }
  });

  map.on("mouseleave", "result-ring-fill", () => {
    map.getCanvas().style.cursor = "";
    popup.remove();
  });

  // Handle mousemove for result-ring layer
  map.on("mousemove", "result-ring", (e) => {
    if (e.features.length > 0) {
      const feature = e.features[0];
      map.getCanvas().style.cursor = "pointer";
      popup
        .setLngLat(e.lngLat)
        .setHTML(`<h4 style="color : black;">${feature.properties.name}</h4>`)
        .addTo(map);
    }
  });

  // Handle click events for result-ring layer
  map.on("click", "result-ring", (e) => {
    if (e.features.length > 0) {
      const projectId = e.features[0].properties.project_id;
      dispatch(setCurrentProjectId(projectId));
    }
  });

  // Handle click events for result-ring-fill layer
  map.on("click", "result-ring-fill", (e) => {
    if (e.features.length > 0) {
      const projectId = e.features[0].properties.project_id;
      dispatch(setCurrentProjectId(projectId));
    }
  });

  map.on("mouseleave", "result-ring", () => {
    map.getCanvas().style.cursor = "";
    popup.remove();
  });
};

export const updateMapWithBufferingData = (map, bufferingData, lineColors, dispatch, setCurrentProjectId) => {
  if (map && bufferingData) {
    const features = Object.values(bufferingData).map(projectData => ({
      type: "Feature",
      properties: { ...projectData },
      geometry: projectData.geom,
    }));

    if (features.length) {
      // Remove existing layers if they exist
      ['result-ring-fill', 'result-ring'].forEach(layerId => {
        if (map.getLayer(layerId)) {
          map.removeLayer(layerId);
        }
      });

      // Remove existing source if it exists
      if (map.getSource("result-ring")) {
        map.removeSource("result-ring");
      }

      // Add new source
      map.addSource("result-ring", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features,
        },
      });

      // Add fill layer
      map.addLayer({
        id: "result-ring-fill",
        type: "fill",
        source: "result-ring",
        paint: {
          "fill-color": lineColors.resultRing,
          "fill-opacity": 0.1,
        },
      });

      // Add line layer
      map.addLayer({
        id: "result-ring",
        type: "line",
        source: "result-ring",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": lineColors.resultRing,
          "line-width": 2,
        },
      });

      // Setup interactions
      setupMapInteractions(map, dispatch, setCurrentProjectId);
    } else {
      console.log('No features to add to the map.');
    }
  }
};