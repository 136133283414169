import React from 'react';
import { Box } from '@mui/material';
import MapComponent from '../components/MapComponent'; 
import ErrorBoundary from '../components/ErrorBoundary';

const ReportPage = () => {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <ErrorBoundary>
        <MapComponent
          center={[139.6917, 35.6895]}
          zoom={10}
          highResolution={true}
          drawTools={false} 
          overlay={true}
        />
      </ErrorBoundary>
    </Box>
  );
};

export default ReportPage;
