import { getShapesByProjectId } from "../../api/project";
import { getColorByType } from "../../helpers/functions";
import mapboxgl from 'mapbox-gl';

export const addShapesToMap = async (mapInstance, drawInstance, projectId, center, zoom, lineWidth, overlay, setError) => {
    if (!mapInstance) {
        // console.error("Map instance is undefined.");
        return;
    }
    if (!projectId) {
        // console.error("Project ID is undefined.");
        return;
    }

    try {        
        const shapes = await getShapesByProjectId(projectId);

        const features = shapes.map((shape) => ({
            type: "Feature",
            properties: {
                id: shape.shape_id,
                landcover_type: shape.landcover_type,
            },
            geometry: shape.geom,
            id: shape.shape_id,
        }));

        // Save the current map state
        const currentCenter = mapInstance.getCenter();
        const currentZoom = mapInstance.getZoom();

        // Set features in draw instance if it exists
        if (drawInstance?.current) {
            drawInstance?.current?.set({
                type: "FeatureCollection",
                features: features,
            });
        }

        // Add the source to the map if it doesn't already exist
        if (!mapInstance.getSource("shapes")) {
            mapInstance.addSource("shapes", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: features,
                },
            });
        } else {
            // Update the existing source
            mapInstance.getSource("shapes").setData({
                type: "FeatureCollection",
                features: features,
            });
        }

        // Add the line and fill layers if they don't exist
        if (!mapInstance.getLayer("shapes")) {
            mapInstance.addLayer({
                id: "shapes",
                type: "line",
                source: "shapes",
                layout: {
                    "line-join": "round",
                    "line-cap": "round",
                },
                paint: {
                    "line-color": [
                        "match",
                        ["get", "landcover_type"],
                        ...getColorByType(),
                        getColorByType()[1], // Default color fetched dynamically
                    ],
                    "line-width": lineWidth,
                },
            });
        }

        if (!mapInstance.getLayer("shapes-fill")) {
            mapInstance.addLayer({
                id: "shapes-fill",
                type: "fill",
                source: "shapes",
                layout: {},
                paint: {
                    "fill-color": [
                        "match",
                        ["get", "landcover_type"],
                        ...getColorByType(),
                        getColorByType()[1], // Default fill color fetched dynamically
                    ],
                    "fill-opacity": 0.01,
                },
            });
        }

        // Pan and zoom to fit the polygons if overlay is not active
        if (features.length > 0) {
            const bounds = new mapboxgl.LngLatBounds();
            features.forEach((feature) => {
                const { geometry } = feature;
                if (geometry.type === "Polygon") {
                    geometry.coordinates.forEach((ring) => {
                        ring.forEach(([lng, lat]) => bounds.extend([lng, lat]));
                    });
                } else if (geometry.type === "MultiPolygon") {
                    geometry.coordinates.forEach((polygon) => {
                        polygon.forEach((ring) => {
                            ring.forEach(([lng, lat]) => bounds.extend([lng, lat]));
                        });
                    });
                }
            });

            if (!bounds.isEmpty()) {
                mapInstance.fitBounds(bounds, {
                    padding: 50,
                    maxZoom: 15,
                    duration: 1000,
                });
            }
        }

        // Pan to the shape (first feature center) if there are features
        if (features.length > 0) {
            const firstFeature = features[0];
            const [lng, lat] = firstFeature.geometry.type === "Polygon"
                ? firstFeature.geometry.coordinates[0][0]
                : firstFeature.geometry.coordinates[0][0][0]; // For MultiPolygon
            mapInstance.flyTo({
                center: [lng, lat],
                zoom: currentZoom, // Maintain current zoom while panning to the shape
                duration: 1000,
            });
        } else {
            // Restore the original map state if no shapes are found
            mapInstance.flyTo({
                center: currentCenter,
                zoom: currentZoom,
                duration: 1000,
            });
        }
    } catch (error) {
        console.error("Error fetching and adding shapes to map:", error);
        setError(error.message);
    }
};
