import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { store } from '../redux/store';
import { setToken } from '../redux/authSlice';
import { logout } from '../utils/authUtils/logout';
import { setServerError } from '../redux/errorSlice';

const axiosAPI = axios.create({
    baseURL: `${process.env.REACT_APP_UE_API}`,
});

// Add a request interceptor to add the token before each request
axiosAPI.interceptors.request.use(
    async (config) => {
        try {
            const session = await fetchAuthSession();
            const token = session?.tokens?.accessToken?.toString();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
                store.dispatch(setToken(token));
            }
        } catch (error) {
            console.error('Error getting session:', error);
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosAPI.interceptors.response.use((response) => {
    return response
},
    async (error) => {

        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const session = await fetchAuthSession({ forceRefresh: true });
                const newAccessToken = session?.tokens?.accessToken?.toString();
                if (newAccessToken) {
                    store.dispatch(setToken(newAccessToken));
                    originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosAPI(originalRequest);
                } else {
                    throw new Error('Session is empty. Logging out.');
                }
            } catch (authError) {
                logout()
            }
        }

        if ((error.response && error.response.status === 500) || 
            error.code === "ERR_NETWORK" || 
            error.code === 'ERR_CONNECTION_REFUSED'
        ) {
            store.dispatch(setServerError());
        }
    
        return Promise.reject(error);
    });

export default axiosAPI;