import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import projectReducer from './projectSlice';
import socketReducer from './socketSlice';
import errorReducer from './errorSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    project: projectReducer,
    socket: socketReducer,
    error: errorReducer
  },
});
