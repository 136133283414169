import mapboxgl from 'mapbox-gl';

export const addResultsToMap = (mapInstance, results, lineColors, t) => {

    if (mapInstance.current && results && results.length > 1) {
        const currentCenter = mapInstance.current.getCenter();
        const currentZoom = mapInstance.current.getZoom();
        const features = results
            .map((result) => ({
                type: "Feature",
                properties: {
                    shizendo: result.shizendo,
                    status_by_code: result.status_by_code,
                    project_id: result.project_id,
                    shizendo_max: result.shizendo_max,
                    extentOfLandAlteration_area: result.extentOfLandAlteration_area,
                    completescopeofwork_area: result.completescopeofwork_area,
                    dai_n: result.dai_n,
                    chu_n: result.chu_n,
                    sai_n: result.sai_n,
                    hanrei_exp: result.hanrei_exp,
                },
                geometry: result.geom,
            }))
            .sort((a, b) => b.properties.shizendo - a.properties.shizendo);

        // Remove existing layers and sources if they already exist
        if (mapInstance.current.getLayer("results-fill")) {
            mapInstance.current.removeLayer("results-fill");
        }
        if (mapInstance.current.getSource("results")) {
            mapInstance.current.removeSource("results");
        }

        // Add the source
        mapInstance.current.addSource("results", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: features,
            },
        });

        // Add the combined fill and outline layer
        mapInstance.current.addLayer({
            id: "results-fill",
            type: "fill",
            source: "results",
            filter: [">=", ["get", "shizendo"], 1],
            paint: {
                "fill-color": lineColors.resultsFill,
                "fill-opacity": 0.5,
                "fill-outline-color": "#000000",
            },
        });

        // Pan to the second polygon
        const secondResult = results[1];
        const polygon = secondResult.geom.coordinates[0];
        const bounds = new mapboxgl.LngLatBounds();
        polygon.forEach((coord) => bounds.extend(coord));
        mapInstance.current.fitBounds(bounds, { padding: 50, maxZoom: 10.5 });

        mapInstance.current.flyTo({
            center: currentCenter,
            zoom: currentZoom,
        });

        // Add cursor change on hover
        mapInstance.current.on("mousemove", "results-fill", () => {
            mapInstance.current.getCanvas().style.cursor = "pointer";
        });

        mapInstance.current.on("mouseleave", "results-fill", () => {
            mapInstance.current.getCanvas().style.cursor = "";
        });

        // Popup logic for `results-fill`
        const createPopupDescription = (properties, fields) => {
            return fields
                .map((field) => {
                    const label = field.label;
                    const value = properties[field.key] ?? "";
                    return `<strong style="color : black;">${label}:</strong> ${value}<br/>`;
                })
                .join("");
        };

        mapInstance.current.on("click", "results-fill", (e) => {
            const feature = e.features[0];

            const fillFields = [
                { key: "dai_n", label: t("app.daiN") },
                { key: "chu_n", label: t("app.chuN") },
                { key: "sai_n", label: t("app.saiN") },
                { key: "hanrei_exp", label: t("app.hanreiExp") },
                { key: "shizendo", label: t("app.shizendo") },
            ];

            const description = `
                <div style="color : black;">
                <strong>${t("app.fillInfo")}</strong><br/><br/>
                ${createPopupDescription(feature.properties, fillFields)}
                </div>
            `;

            new mapboxgl.Popup({ closeButton: true, closeOnClick: true })
                .setLngLat(e.lngLat)
                .setHTML(description)
                .addTo(mapInstance.current);
                
        });
    }
};
