import { polygonOptions } from "../resources/projectFormOptions";

// getColorByType now returns an array suitable for mapboxgl's match expression
const getColorByType = () => {
  const defaultColor = polygonOptions.find(option => option.value === 'completeScopeOfWork').colour;
  return polygonOptions.reduce((acc, option) => {
    acc.push(option.value, option.colour || defaultColor);
    return acc;
  }, []);
};



export {
  getColorByType
};